<template>
  <div class="hk_page_routerview">
    <div class="hk_page_title">权限管理（当前页面开放给超级管理员）
      <div class="hk_page_title_right">
        <el-button type="primary" size="small" plain @click="ifShowAdd=true">新建角色</el-button>
      </div>
    </div>
    <div class="hk_page_wrap">
      <div class="hk-common-page">
        <div class="hk_result_total_box">
          共有 <span class="hk-font-highlight">{{tableData.length}}</span> 个角色
        </div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="角色ID" min-width="20%">
            <template slot-scope="scope">
              {{ scope.row.eeoCourseId }}
            </template>
          </el-table-column>
          <el-table-column label="角色名称" min-width="30%">
            <template slot-scope="scope">
              {{ scope.row.courseName }}
            </template>
          </el-table-column>

          <el-table-column label="操作" min-width="50%">
            <template slot-scope="scope">
              <span class="hk-table-option hk-table-option-first" @click="ifSetDialog=true">设置权限</span>
              <span class="hk-table-option" @click="showEditFun(scope.row)">编辑</span>
              <el-popover placement="top-end" width="280" v-model="scope.row.ifDelTop" trigger="click">
                <p class="popover-option"><i class="el-icon-warning hk-font-highlight" style="font-size: 16px;"></i>&nbsp;确认删除吗？</p>
                <div style="text-align: right; margin: 20px 0 0">
                  <el-button size="mini" @click="cancelDeleteFun(scope.row)">取消</el-button>
                  <el-button type="primary" size="mini" @click="confirmDeleteFun(scope.row)">删除</el-button>
                </div>
                <span class="hk-table-option" slot="reference">删除</span>
              </el-popover>
            </template>
          </el-table-column>

          <div slot="empty">
            <p>没有你要找的数据哦！</p>
          </div>
        </el-table>
      </div>
    </div>

    <!-- 设置权限 ifSetDialog -->
    <el-dialog :title="'设置权限'" :visible.sync="ifSetDialog" :modal-append-to-body="false" width="550px">
      <el-form label-width="94px" size="small" :rules="dataRules" ref="dataForm" :model="dataForm">
        <el-form-item label="数据权限：" prop="dataHas">
          <el-radio-group v-model="dataForm.dataHas">
            <el-radio label="all">全量数据</el-radio>
            <el-radio label="about">相关数据</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="功能权限：" prop="funHas">
          <el-tree :data="dataTree" show-checkbox node-key="id" :default-expand-all="true" :props="defaultProps" style="max-height:400px;overflow-y:auto;">
          </el-tree>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ifSetDialog=false" size="small">取消</el-button>
        <el-button type="primary" @click="ifSetDialog=false" size="small">保存</el-button>
      </span>
    </el-dialog>

    <!-- 新建角色  -->
    <el-dialog :title="editId?'编辑角色':'新建角色'" :visible.sync="ifShowAdd" :modal-append-to-body="false" width="400px">
      <el-form label-width="94px" size="small" :rules="addRules" ref="addForm" :model="addForm">
        <el-form-item label="角色名称：" prop="roleName">
          <el-input v-model="addForm.roleName" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddFun" size="small">取消</el-button>
        <el-button type="primary" @click="confirmAddFun('addForm')" size="small">{{editId?'修改':'保存'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [{ id: 1 }, { id: 2 }],

      ifSetDialog: false, //设置权限的弹窗   dataTree-权限的树形结构
      dataTree: [{
        id: 1,
        label: '课程管理',
        children: [
          {
            id: '1-1',
            label: '课程列表查看'
          }, {
            id: '1-2',
            label: '批量导入课程'
          },
          {
            id: '1-3',
            label: '批量导入辅导'
          },
          {
            id: '1-4',
            label: '生成期中报告'
          },
          {
            id: '1-5',
            label: '课节列表查看'
          },
          {
            id: '1-6',
            label: '导入出勤表'
          },
          {
            id: '1-7',
            label: '导入作业表'
          },
        ]
      }, {
        id: 2,
        label: '商品管理',
        children: [{
          id: '2-1',
          label: '抖店商品查看'
        }, {
          id: '2-2',
          label: '小红书商品查看'
        }]
      }, {
        id: 3,
        label: '订单管理',
        children: [{
          id: '3-1',
          label: '抖店订单查看'
        }, {
          id: '3-2',
          label: '小红书订单查看'
        }]
      }, {
        id: 4,
        label: '系统管理',
        children: [
          {
            id: '4-1',
            label: '账号管理'
          },
        ]
      }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      dataForm: {
        dataHas: [], //数据权限
        funHas: [], //功能权限
      },
      dataRules: {
        dataHas: [
          { required: true, message: '请选择', trigger: 'blur' }
        ],
        funHas: [
          { required: true, message: '请选择', trigger: 'blur' }
        ]
      },

      ifShowAdd: false, //新建角色弹窗是否显示
      editId: '', //编辑时的角色id
      addForm: {
        roleName: '', //角色名称
      },
      addRules: {
        roleName: [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {

  },
  methods: {
    //弹窗--保存
    confirmAddFun(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {

        }
      })
    },

    //弹窗--取消
    cancelAddFun() {
      this.ifShowAdd = false
      this.addForm = Object.assign({}, {
        roleName: ''
      }),
        this.editId = ''
    },

    //编辑--显示弹窗
    showEditFun(row) {
      let that = this;
      that.editId = row.id;
      that.$set(that.addForm, 'roleName', row.roleName)
      that.ifShowAdd = true
    },

    //确认删除角色
    confirmDeleteFun(row) {
      let that = this;
      let params = { id: row.id };
      that.$message({
        message: '删除角色提示----',
        type: 'success'
      })
      row.ifDelTop = false
      return false;
      that.request({
        method: 'post',
        url: '/manage/staff/deleteStaff',
        param: params,
        success: function (result) {
          if (result.code == 200) {
            row.ifDelTop = false
            that.getEmployeeListFun()

          } else {
            that.$message.error('删除失败，' + result.message)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error)
          that.$message.error('删除失败了。')
        }
      })
    },
    //取消删除
    cancelDeleteFun(row) {
      row.ifDelTop = false
    },
  }
}
</script>

<style lang="scss" scoped>
</style>